import * as React from 'react'
import { Helmet } from 'react-helmet'
import LayoutPrimary from '../templates/LayoutPrimary'
import { galleryPageContainer } from '../styles/components/galleries.module.scss'
import { h1, sub } from '../styles/components/titles.module.scss'
import '../styles/components/content.module.scss'
import GalleryFull from '../components/galleries/GalleryFull'
import ServicesList from '../components/blocks/ServicesList'

const GalleryPage = () => {
  return (
    <LayoutPrimary>
      <Helmet>
        <title>Party Boat Charter Photo Gallery | Tampa Bay Boating Adventures</title>
        <meta name="description" content="See photos of our private party boat charter trips along the Tampa, Florida coastline and book yours online today!" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className='container'>
        <div className='row center this-pad-y-4-lg this-pad-y-2'>
          <div className='col-xxs-12'>
            <div className={galleryPageContainer}>
              <h1><span className={sub}>Party Boat Charter</span> Photo Gallery</h1>
              <p>View photos of the Tampa Bay Boating Adventures experience!</p>
              <div className='this-pad-y-2'>
                <GalleryFull />
              </div>
              <h2 className={h1}>Select a Charter <span className={sub}>to Start Your Adventure!</span></h2>
              <div className='text-left'>
                <ServicesList />
              </div>
            </div>
          </div>
        </div>
      </main>
    </LayoutPrimary>
  )
}

export default GalleryPage